import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import Copy from '../components/Copy'
import { formatTime } from '../utils/commonFunctions'
import TransactionTable from '../components/TransactionTable'

export default function Block() {

  const [blockDetails, setBlockDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const { block } = useParams()
  const { state } = useLocation()

  const fetchBlockDetailsFromBlockNumber = async () => {
    try {
      const res = await axios.post(`https://fone-network-node.onrender.com/chain/block`, { index: Number(block) })
      setBlockDetails(res?.data || null)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchBlockDetailsFromBlockHash = async () => {
    try {
      const res = await axios.get(`https://fone-network-node.onrender.com/block/${block}`)
      if (res?.data?.block)
        setBlockDetails(res?.data?.block || null)
      else
        fetchBlockDetailsFromBlockNumber()
    } catch (err) {
      fetchBlockDetailsFromBlockNumber()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (state) setBlockDetails(state)
    else if (block) fetchBlockDetailsFromBlockHash()
  }, [state, block])

  return (
    <>
      <Header className={'header_bg'} />
      <div className='explorer_bg block_space pb-5'>
        <section className='block_table'>
          <div className='container'>
            <div className=''>
              <Link to={-1} className='back_button'>
                <img src="/assets/image/back_icon.svg" alt='Back Icon' />
                <p>Back</p>
              </Link>
            </div>
            <div className='block_id d-flex align-items-center'>
              <p>Block {block?.length == 64 && 'Hash'}</p> {block && <span>#{block}</span>}
            </div>
            <div className='blocks_card'>
              <div className='card_body'>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Index</p>
                  </div>
                  <div className='block_data'>
                    <p>{blockDetails?.index || '-'}</p>
                  </div>
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Block hash</p>
                  </div>
                  {
                    blockDetails?.hash ?
                      <div className='block_data gap-2'>
                        <Link to={`/block/${blockDetails?.hash}`} className='font_gray'>{blockDetails?.hash}</Link>
                        <Copy value={blockDetails.hash} />
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Previous hash</p>
                  </div>
                  {
                    blockDetails?.previousBlockHash ?
                      <div className='block_data gap-2'>
                        <span>{blockDetails.previousBlockHash}</span>
                        <Copy value={blockDetails.previousBlockHash} />
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Timestamp</p>
                  </div>
                  {
                    blockDetails?.timestamp ?
                      <div className='block_data gap-2'>
                        <img src='/assets/image/watch_icon.svg' alt="icon" />
                        <p>{formatTime(blockDetails.timestamp)}</p>
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Transactions</p>
                  </div>
                  <div className='block_data gap-2'>
                    <p>{blockDetails?.transactions?.length || 0} transactions in this block</p>
                  </div>
                </div>
              </div>
            </div>
            <TransactionTable
              totalTx={blockDetails?.transactions || []}
              loading={loading}
              setLoading={setLoading}
            />
            <div className='block_data align-items-start pt-4'>
              <img src='/assets/image/bulb_icon.svg' alt="icon" />
              <p className='pt-0'>Blocks are batches of transactions linked via cryptographic hashes. Any tampering of a block would invalidate all following blocks as all subsequent hashes would change. Learn more about this page in our <Link className='text-decoration-underline' to={'/whitepaper'}>Whitepaper</Link>.</p>
            </div>
          </div>
        </section>
      </div>
      <Footer className={'white_bg'} />
    </>
  )
}