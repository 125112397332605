import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function WhitePaper() {
    return (
        <>
            <Header />

            <section className='section_style hero_section whitepaper_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='text-center mb-5'>
                                <img src="/assets/image/favicon.svg" alt="Fone Network" className='whitepapar_favicon' />
                                <h1 className='hero_title'>Fone Network Whitepaper</h1>
                                <h2>Ver 0.3</h2>
                                <p className='mb-0'>August, 2024</p>
                            </div>
                            <div>
                                <h2>Introduction:</h2>
                                <p>
                                    The Fone Network is a custom blockchain designed specifically for mobile devices, including those running on Android or iOS. FONE, the native currency, powers the network. Embracing decentralization, simplicity, and mobile accessibility, Fone Network is entirely community-run and user-controlled. The focus on user-friendliness caters to those entering the world of blockchain and cryptocurrencies. The primary mode of interaction with the blockchain and FONE Coin is through mobile devices, emphasizing ease of access anytime, anywhere.
                                </p>
                            </div>
                            <div>
                                <h2>Blockchain Access:</h2>
                                <p>
                                    Participation in the Fone Network begins with the creation of a digital wallet, generating a unique "key pair" using the SECP-256K1 elliptic curve. The resulting "Fone Address" is a 64-character string derived from the X value, ensuring efficiency for mobile use. Transactions involve the amount of FONE, recipient address, timestamp, and optional message, all digitally signed by the initiator's private key. A fixed fee of 0.12 FONE, known as the "Network Fee," is deducted from each pending transaction, with 0.02 FONE burned for deflationary impact.
                                </p>
                                <p><strong>Example</strong>: "If Bob sends 10 FONE to Alice, Alice will receive 9.88 FONE."</p>
                                <p>Users can manually burn FONE by sending it to the "Burn Address" (64 zeroes), permanently removing it from circulation.</p>
                            </div>
                            <div>
                                <h2>Validation:</h2>
                                <p>
                                    After submission, transactions undergo a validation step. Five randomly chosen nodes validate each transaction every twelve seconds using a validator software that runs on most operating systems. Each of the five validators receives the same batch of pending transactions and validates their integrity by checking the available balance of each sender, alongside the recipient address to be in a valid Fone Network format (starting with a lowercase "f"), and the time when the transaction was created. Validators receive 0.02 FONE per validated transaction.
                                </p>
                            </div>
                            <div>
                                <h2>Incentives:</h2>
                                <p>
                                    <strong>Passive:</strong>
                                    <br />
                                    Participants can stake FONE (0.01% to 0.1% of Total Supply) in the Network Stake, earning staking rewards (4% to 10% yearly) available to be claimed every 24 hours. Participants can unstake their FONE at any time after 72 hours of beginning to stake. This process and the rewards are how additional FONE is generated (called "minting") and enters circulation.
                                </p>
                                <p>
                                    <strong>Active:</strong>
                                    <br />
                                    An active method to earn Fone is by validating transactions on the Network. To become a validator, you must first stake some of your FONE. Next, you must run software on a device that can execute JavaScript applications (such as a laptop, Raspberry Pi, Cloud Service, etc). This is called running a "Validator Node". To properly set up a Validator Node, you must have some technical and networking experience, as it does require port-forwarding and, in some cases, a static IP address.
                                </p>
                                <p>Since Fone Network is built with mobile devices in mind, no special hardware is required to validate transactions, which equalizes the ability for everyone to participate in securing and supporting the blockchain ledger.</p>
                                <p>Each Node must also be connected to a wallet via the private key to verify that it has staked FONE and to configure access to the device.</p>
                                <p>Upon successful configuration and launch, the device will download a complete copy of the entire blockchain before operating as a full Node on the Network. Once up and running, Nodes can receive and validate pending transactions for a reward of 0.02 FONE per successful validation.</p>
                            </div>
                            <div>
                                <h2>Validators/Nodes:</h2>
                                <p>
                                    Every time pending transactions are to be finalized into a block (every 12 seconds), this requires the validation of five randomly chosen nodes. These five nodes are chosen randomly by the Network based on the following criteria:
                                </p>
                                <p><strong>1. Proof of Stake:</strong> One out of the top ten participants that have staked the most FONE</p>
                                <p><strong>2. Proof of Time:</strong> One out of the top ten participants that have staked for the longest period of time (excluding any nodes selected to be in position 1)</p>
                                <p><strong>3. Proof-of-Authority:</strong> One out of the top ten participants with the highest “rank” (excluding any nodes selected to be in positions 1 - 2)</p>
                                <p><strong>4.</strong> Randomly chosen from all nodes on the network (excluding any nodes selected to be in positions 1 - 3)</p>
                                <p><strong>5.</strong> Randomly chosen from all nodes on the network (excluding any nodes selected to be in positions 1 - 4)</p>
                                <p>The "Node Leader" is the Node responsible for selecting the five nodes validating the next block. The node leader will initially be the official Fone Node, run by the developers. Once at least ten nodes are on the Network, the node leadership system will take over and decide future node leaders.</p>
                                <p>Each time a Node validates a block, its "Rank" is increased by a value of one, resulting in a higher chance to be chosen to validate future blocks.</p>
                                <p>If a Validator/Node unstakes their FONE, their Rank and staking timestamp are reset to zero.</p>
                            </div>
                            <div>
                                <h2>Validator Setup:</h2>
                                <p>
                                    The validator software is currently available for Windows (32 and 64-bit), MacOS, and Linux. As of July 31st, 2024, it is provided to all Alpha testers via email with attached instructions for setting it up. The software will be available to beta testers in August 2024 to test the full capabilities of selecting/deselecting validators.
                                </p>
                            </div>
                            <div>
                                <h2>Requirements:</h2>
                                <p>
                                    <strong>1.</strong> Windows, Mac, or Linux-based system
                                </p>
                                <p>
                                    <strong>2.</strong> Fone Network Validator Software (currently available for Alpha and Beta testers via email sent directly to them)
                                </p>
                                <p>
                                    <strong>3.</strong> A minimum of 5GB storage (during testing)
                                </p>
                                <p>
                                    <strong>4.</strong> Active internet connection
                                </p>
                                <p>
                                    <strong>5.</strong> Stationary setup with Modem/Router (home/office)
                                </p>
                                <p>
                                    <strong>6.</strong> Port-forwarding to access the device externally
                                </p>
                                <p>
                                    <strong>7.</strong> Fone Network Wallet that stakes Fone through the mobile App
                                </p>
                            </div>
                            <div>
                                <h2>Step-by-step Guide:</h2>
                                <p>
                                    After successfully downloading the validator software for the desired operating system (OS), unarchiving the file will create a folder with three files inside: a SetupGuide.txt that contains this guide with some differences based on the OS, a config.json, and an executable Validator file.
                                </p>
                                <p>
                                    The first step is to note a few details. Depending on the OS, there are several ways to find a device's IP address. Opening CMD (command prompt) and typing "ipconfig" will show the device's IPv4 address for Windows. It usually looks similar to 192.168.1.150, with the last two digits possibly different.
                                </p>
                                <p>
                                    This will be the address we need to open a port to and help us find the router address. Using the example above, we know that the router address will be 192.168.1.1 (the first address on the internal Network). Navigating to the router address in a browser of choice will allow access. After a successful login to the router, depending on the brand, a user must navigate to the NAT/Port Forwarding section, where it must be enabled, and a new port must be opened.
                                </p>
                                <p>
                                    On a status page, note the IP address of the Network (Static or DHCP), or navigate to www.whatismyip.com and write down the IPv4 one. Example 12.34.56.78
                                </p>
                                <p>
                                    The details that need to be entered are the name of the Service (any name will do), the IP address of the device that will run the Validator software (in this example, 192.168.1.150), the External Port (the port attached to the Static IP of the Network; DO NOT USE 80; this is the default and is not secure), the Internal Port (Choose any port, for example 3001), and the type (TCP).
                                </p>
                                <p>
                                    Open the config.json file and replace the all-capital letter words.
                                </p>

                                <pre><code>{`{
                                    "port": INTERNAL_PORT,
                                    "nodeURLs": ["https://fone-network-node.onrender.com"],
                                    "ip": "IP_ADDRESS:EXTERNAL_PORT",
                                    "privateKey": "PRIVATE_KEY"
}`}</code></pre>
                                <p>
                                    Example based on the information above:
                                </p>
                                <pre><code>{`{
                                    "port": 3001,
                                    "nodeURLs": ["https://fone-network-node.onrender.com"],
                                    "ip": "12.34.56.78:8081",
                                    "privateKey": “2398wejfs0ejr90qjwg90sjd90fj23ojrwejf9s039rj2ojwefjojes9r23jrsod”
}`}</code></pre>
                                <p>
                                    Save the config.json file and open the Validator file by double-clicking it. If a black window opens and closes immediately, open a terminal window (CMD on Windows, Terminal on Linux) and navigate to the folder where the Validator software is.
                                </p>
                                <p>
                                    <strong>Example:</strong> cd Desktop/validator/
                                </p>
                                <p>
                                    For Windows, type Validator.exe for Linux ./Validator to run the validator, and it will not close automatically upon start.
                                </p>
                                <p>
                                    Based on the error, some steps need to be reconfigured.
                                </p>
                            </div>
                            <div>
                                <h2>Common errors and possible solutions:</h2>
                                <p>
                                    <strong>The validator is not live based on the IP in the config file:</strong> Ensure that the "IP" field is correctly set and that it is not the local IP address but the Network's Static IP (WAN).
                                </p>
                                <p>
                                    <strong>Error reading from config file:</strong> If the format is broken in the config.json file, it will not read properly and display this error. Ensure all key-value pairs have quotations around (but the port) and that commas are present on each line but the last one.
                                </p>
                                <p>
                                    <strong>Invalid private key:</strong> If the Private key is not the correct length, it will display this.
                                </p>
                                <p>
                                    <strong>This wallet is not staking:</strong> The wallet the private key unlocks must start staking to validate transactions before the validator software is executed.
                                </p>
                                <p>
                                    <strong>This is an outdated version of the validator. Please update it to continue:</strong> As the error says, the version is outdated, and the validator software needs to be updated.
                                </p>
                                <p>
                                    <strong>A connection to a Node was not established correctly, check the config file or try again later:</strong> This is the error if none of the provided node URLs are live. (Do not add or remove any now as this is the only Node during testnet)
                                </p>
                                <p>
                                    <strong>Could not read from Node:</strong> If the Node is overloaded, the connection will time out, try again later.
                                </p>
                                <p>
                                    If things are correctly set, the following text in green will be displayed, and the validator will be live on stand-by to validate upcoming transactions:
                                </p>
                                <p className='mb-0'>
                                    Validator running on port "CHOSEN_PORT"
                                </p>
                                <p className='mb-0'>
                                    Connected to Node "NODE IT CONNECTS TO"
                                </p>
                                <p className='mb-0'>
                                    Accessing Wallet "PUBLIC FONE ADDRESS"
                                </p>
                                <p className='mb-0'>
                                    Validator Online is waiting for transactions to validate.
                                </p>
                            </div>
                            <div>
                                <h2>Validator Software breakdown:</h2>
                                <p>
                                    The current version of the validator software is only available for Windows, Mac and Linux as an executable file. Still, with the release of the mainnet, the raw javascript files will be provided so it can be executed on virtually any platform that has Node.js installed, including but not limited to Android and iOS.
                                </p>
                                <p>
                                    The current structure includes:
                                </p>
                                <p>
                                    The config.json file is read by the Validator executable file upon opening to put the variables in the proper places to connect to a node on the Network. It provides the validator's IP/Domain to insert it into the staking details on the blockchain along with the ports open to communicate, and the private key gives access to the public Fone Network wallet that will receive the validator rewards.
                                </p>
                                <p>
                                    The Validator executable consists of two Javascript files, validator.js and constructor.js. The validator.js file is 53 lines long and is responsible for receiving the transactions when selected by the leader node, responding to pings (calls from the Node to determine whether it is active or not), and becoming active when started by running on the given internal port.
                                </p>
                                <p>
                                    The constructor.js file consists of 363 lines of code and is the brain of the Validator software. When selected, it downloads the latest block, goes through the entire ledger to verify transactions, and reads and writes the chain to a local data.json file (to be created upon successful Validator setup).
                                </p>
                            </div>
                            <div>
                                <h2>NFTs:</h2>
                                <p>
                                    Fone Network supports NFT transactions by incorporating data, requiring a predetermined NFT protocol (title, optional description, category, price, image URL).
                                </p>
                            </div>
                            <div>
                                <h2>Fone Tokenomics:</h2>
                                <p>
                                    Since the Fone project started in 2017 and has featured a native token on the Ethereum, Tron and Solana blockchains throughout the years, it has accumulated both on-chain and off-chain supply. This supply has been distributed via apps and games developed by the company and in partnerships. An off-chain Fone Coin cloud earning mechanism was introduced in 2023. The feature rewarded users of the official Fone Network application with the native Fone Coin currency, the primary and only built-in currency that serves the multipurpose of transacting from one wallet to another, a mechanism for paying fees, and transferring digital data in the form of "Messages".
                                </p>
                                <h3>Initial Supply Breakdown:</h3>
                                <p>
                                    As of March 12th, the amount of Fone Token in circulation, staked, and off-chain equals roughly 269,946,770,049, and the amount of off-chain Fone Coin equals approximately 35,139,138,182. To significantly reduce the initial supply of the Fone Coin that enters circulation with the release of the mainnet of the custom mobile Fone Network blockchain, the Fone Token will be converted to Fone Coin at a 100:1 ratio.
                                </p>
                                <p>
                                    After calculations, it was discovered that during the Cloud Earning of Fone Coin, the system has rewarded more Fone Coin than initially anticipated due to misconfiguration and/or cloud functionality altering. The off-chain Fone Coin will get converted to the on-chain Fone Coin at a 20:1 ratio.
                                </p>
                                <p>
                                    This means that around 2.7B (Billion) Fone Coin will be minted with the Genesis block to cover the Fone Token conversions and approximately 1.75B to cover the off-chain Fone Coin conversions.
                                </p>
                                <p>
                                    100M Fone Coin will be minted and allocated towards the new Cloud Earning airdrop. 360M will be minted for marketing and advertising to help popularize the new blockchain. 320M Fone Coin will be minted to cover all the remaining Premium and Power Earning sessions from the legacy version.
                                </p>
                                <p>
                                    Finally, 770M will be minted and distributed to the team, advisors, and company reserve.
                                </p>
                                <p>
                                    The approximate initial supply of Fone Coin will be 12 Billion, which is subject to change from March 12th, 2024, until the release of the mainnet, which is scheduled to go live by the end of Q3 2024.
                                </p>
                                <h3>Fone Supply Allocation:</h3>
                                <p>
                                    Community: 4.56B (38%)
                                </p>
                                <p>
                                    Investors: 2.4B (20%)
                                </p>
                                <p>
                                    Team: 1.44B (12%)
                                </p>
                                <p>
                                    Treasury: 2.4B (20%)
                                </p>
                                <p>
                                    Liquidity: 600M (5%)
                                </p>
                                <p>
                                    Advisors: 600M (5%)
                                </p>
                                <h3>Inflation Schedule:</h3>
                                <p>
                                    Fone's inflation rate is determined by the amount of Fone staked. Starting at 10% staking rewards yearly, the rate drops proportionally to 4%, calculated based on the number of people staking and the total amount staked. Since staking is the only way to mint new Fone Coin, the average yearly inflation rate is 7%. On a 6B supply, the average early inflation is 420M. Participants in the Fone Network can choose to send Fone to a dedicated "Burn Address", increasing the amount burned and reducing inflation. Each transaction on the Fone Network burns 0.02 Fone automatically, which should be considered when calculating the yearly inflation.
                                </p>
                                <p>
                                    Example: 1B transactions yearly will result in 20,000,000 Fone burned.
                                </p>
                                <h3>Migration Process:</h3>
                                <p>
                                    Converting Fone Token and off-chain Fone Coin requires user action and will not be converted automatically if left on exchanges; therefore, all Fone Tokens must be deposited back into the Fone Network app. To migrate the legacy currencies to Fone Coin, users need to open the Fone Network app, navigate to the "More" page, and then click on the "Migrate Old Account" section. After a successful login, all migratable account data and assets will be present, and it will be recorded on the blockchain with the click of a button. The Migration process will be available from the release date of the mainnet for precisely one year. After the migration window closes, all unclaimed Fone will get burned.
                                </p>
                                <h3>Vesting Schedule:</h3>
                                <p>
                                    To contribute to the project's long-term success and increase its sustainability, all Fone minted in the Genesis block will have a 20-month vesting period upon distribution to the community, a 12-month vesting upon distribution to the investors, a 6-month cliff, then an 18-month vesting for team, founders, and advisors. Once migration is performed, Five percent of that Fone will become available immediately to each community member, with equal increments of 5 percent monthly.
                                </p>
                                <h3>Exchange Listings:</h3>
                                <p>
                                    Since the Fone Network blockchain is built from the ground up with mobile devices in mind, its approach differs from that of traditional blockchain projects. Therefore, exchanges estimate an implementation time of around 30 days from the mainnet release date. Since the Fone Token and the off-chain Fone Coin are not converted at a 1:1 ratio, the on-chain Fone Coin will be listed at a higher price than the last price to maintain its value.
                                </p>
                            </div>
                            <div>
                                <h2>Privacy & Security:</h2>
                                <p>
                                    Due to the nature of wallet creation on the Fone Network, no personal details are required or stored, and users are only identified by a random 64-character string (Fone Address).
                                </p>
                                <p>Wallet balances are always calculated live by analyzing the entire blockchain to verify the current available balance. No “current values” are ever stored as total or balances, ensuring that any balance displayed has resulted from a calculation of the full history (ledger) of that wallet. Any FONE you are staking, along with any pending transactions, are removed from your “Available Balance” to prevent the possibility of double-spending.
                                </p>
                                <p>Submitting a transaction requires the use of a digital signature generated by the private key of the initiator of the transaction, eliminating any possibility of a third-party to submit a transaction on behalf of another user.
                                </p>
                                <p>A transaction is only considered completed, or verified, once all five randomly selected Validators confirm the validity of each transaction. This is done by confirming the availability of funds to send from each wallet, along with verifying that each transaction hash matches the hash from its initial submission. This multi-step process ensures the authenticity and validity of all transactions, and therefore all balances to be calculated using this ledger of transactions (the “blockchain”). Each block requires a 100% consensus among the Validators for it to be approved and broadcast to the network.
                                </p>
                                <p>Each block refers to the last via its hash, meaning that if even a single value is ever changed, that block will fail validation, and the user that broadcasted it will be permanently removed from the Fone Network, ensuring the validity of the network as whole.</p>
                            </div>
                            <div>
                                <h2>Conclusion:</h2>
                                <p>
                                    The Fone Network, driven by its core principles of decentralization, simplicity, and mobile access, aims to redefine blockchain interaction for mobile users. With a robust validation process, innovative incentive mechanisms, and support for NFTs, Fone Network provides a secure and efficient platform for cryptocurrency transactions. By prioritizing user-friendliness and community involvement, Fone Network stands at the forefront of accessible and decentralized blockchain technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default WhitePaper