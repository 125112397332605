import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { formatTime, getStatus } from '../utils/commonFunctions'
import Copy from '../components/Copy'
import TransactionTable from '../components/TransactionTable'

export default function Address() {

  const [addressData, setAddressData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [firstTx, setFirstTx] = useState(null)
  const [lastTx, setLastTx] = useState(null)
  const { address } = useParams()
  const { state } = useLocation()

  useEffect(() => {
    if (addressData?.addressTransactions?.length) {
      setFirstTx(addressData.addressTransactions?.at(0) || {})
      setLastTx(addressData.addressTransactions?.at(-1) || {})
    }
  }, [addressData])

  useEffect(() => {
    if (state) setAddressData(state?.addressData)
    else if (address) fetchAddressData()
  }, [state, address])

  const fetchAddressData = async () => {
    try {
      setLoading(true)
      setAddressData(null)
      const res = await axios.get(`https://fone-network-node.onrender.com/address/${address}`)
      setAddressData(res?.data?.addressData || null)
    } catch (err) {
      console.log(err)
    } {
      setLoading(false)
    }
  }

  return (
    <>
      <Header className={'header_bg'} />
      <div className='explorer_bg block_space pb-5'>
        <section className='block_table'>
          <div className='container'>
            <div className=''>
              <Link to={-1} className='back_button'>
                <img src="/assets/image/back_icon.svg" alt='Back Icon' />
                <p>Back</p>
              </Link>
            </div>
            <div className='block_id user_address d-flex'>
              <p>Address</p>
              <div>
                <span>{address}</span>
                <Copy value={address} />

              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-md-0 mb-4'>
                <div className='services_card address_card justify-content-between h-100'>
                  <div className='fone_balance'>
                    <p className='explorer_pera'>Overview</p>
                  </div>
                  <div>
                    <span>FONE Balance:</span>
                    <div className='main_balance d-flex align-items-center'>
                      <img src='/assets/image/favicon_dark.svg' alt="icon" />
                      <p>{addressData?.addressBalance ? Number(Number(addressData?.addressBalance)?.toFixed(2))?.toLocaleString() : 0} FONE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='services_card address_card'>
                  <div className='fone_balance'>
                    <p className='explorer_pera'>More Info</p>
                  </div>
                  <div>
                    <span>Last Txn sent</span>
                    {
                      lastTx?.hash ?
                        <div className='info_address'>
                          <div className='d-flex align-items-start gap-2'>
                            <Link to={`/tx/${lastTx.hash}`} className='font_gray'>
                              {lastTx.hash?.substr(0, 30) + '...'}
                            </Link>
                            <Copy value={lastTx.recipient} />
                          </div>
                          {lastTx?.timestamp && <span>from {formatTime(lastTx.timestamp)}</span>}
                        </div>
                        : <div>-</div>
                    }
                  </div>
                  <div>
                    <span>First Txn Sent</span>
                    {
                      firstTx?.hash ?
                        <div className='info_address'>
                          <div className='d-flex align-items-start gap-2'>
                            <Link to={`/tx/${firstTx.hash}`} className='font_gray'>
                              {firstTx.hash?.substr(0, 30) + '...'}
                            </Link>
                            <Copy value={firstTx.recipient} />
                          </div>
                          {firstTx?.timestamp && <span>from {formatTime(firstTx.timestamp)}</span>}
                        </div>
                        : <div>-</div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <TransactionTable
              totalTx={addressData?.addressTransactions || []}
              loading={loading}
              setLoading={setLoading}
            />
            <div className='block_data align-items-start pt-4'>
              <img src='/assets/image/bulb_icon.svg' alt="icon" />
              <p className='pt-0'>A wallet address is a publicly available address that allows its owner to receive funds from another party. To access the funds in an address, you must have its private key. Learn more about addresses in our <Link className='text-decoration-underline' to={'/whitepaper'}>Whitepaper</Link>.</p>
            </div>
          </div>
        </section>
      </div>
      <Footer className={'white_bg'} />
    </>
  )
}