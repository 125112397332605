import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { formatTime, getStatus } from '../utils/commonFunctions'
import Copy from '../components/Copy'

export default function TransactionDetails() {

  const [txDetails, setTxDetails] = useState(null)
  const [blockDetails, setBlockDetails] = useState(null)
  const [nftDetails, setNftDetails] = useState(null)
  const { txHash } = useParams()
  const { state } = useLocation()

  const fetchTxData = async () => {
    try {
      const res = await axios.get(`https://fone-network-node.onrender.com/transaction/${txHash}`)
      setTxDetails(res?.data?.transaction || null)
      setBlockDetails(res?.data?.block || null)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (state) {
      setTxDetails(state?.transaction || null)
      setBlockDetails(state?.block || null)
    } else if (txHash) fetchTxData()
  }, [state, txHash])

  useEffect(() => {
    if (txDetails && txDetails.message) {
      isContainNFT(txDetails.message)
    }
  }, [txDetails])

  function isContainNFT(message) {
    try {
      const msgObj = JSON.parse(message)
      if (typeof msgObj === 'object' && 'i' in msgObj) {
        const imgUrl = `https://firebasestorage.googleapis.com/v0/b/phoneum-go.appspot.com/o/nfts%2F${msgObj.i?.substring(0, 15)}?alt=media&token=${msgObj.i?.substring(15)}`
        setNftDetails({
          name: msgObj.n || '',
          description: msgObj.d || '',
          url: imgUrl
        })
      }
    } catch (error) {
      return false
    }
  }

  return (
    <>
      <Header className={'header_bg'} />
      <div className='explorer_bg block_space pb-5'>
        <section className='block_table'>
          <div className='container'>
            <div className=''>
              <Link to={-1} className='back_button'>
                <img src="/assets/image/back_icon.svg" alt='Back Icon' />
                <p>Back</p>
              </Link>
            </div>
            <div className='block_id d-flex align-items-center'>
              <p>Transaction Details</p>
            </div>
            <div className='blocks_card'>
              <div className='card_body'>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Transaction Hash</p>
                  </div>
                  {
                    txDetails?.hash ?
                      <div className='block_data gap-2'>
                        <p>{txDetails?.hash}</p>
                        <Copy value={txDetails.hash} />
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Status</p>
                  </div>
                  {
                    txDetails?.status != undefined ?
                      <div className={`block_data ${txDetails?.status == 0 ? 'block_status_success' : 'block_status_pending'}`}>
                        <img src={`/assets/image/${txDetails?.status == 0 ? 'success_icon.svg' : 'information_icon.svg'}`} alt="icon" />
                        <span>{getStatus(txDetails.timestamp)}</span>
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Block</p>
                  </div>
                  <div className='block_data'>
                    <p>{blockDetails?.index ? <Link to={`/block/${blockDetails.index}`} className='font_gray'>{blockDetails.index}</Link> : 0}</p>
                  </div>
                </div>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Amount</p>
                  </div>
                  <div className='block_data'>
                    <p>{txDetails?.amount ? Number(Number(txDetails?.amount)?.toFixed(2))?.toLocaleString() : 0} FONE</p>
                  </div>
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Sender</p>
                  </div>
                  {
                    txDetails?.sender ?
                      <div className='block_data gap-2'>
                        <Link to={`/address/${txDetails.sender}`} className='font_gray'>{txDetails.sender}</Link>
                        <Copy value={txDetails.sender} />
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Recipient</p>
                  </div>
                  {
                    txDetails?.recipient ?
                      <div className='block_data gap-2'>
                        <Link to={`/address/${txDetails.recipient}`} className='font_gray'>{txDetails.recipient}</Link>
                        <Copy value={txDetails.recipient} />
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div d-flex flex-row align-items-center'>
                  <div className='block_data tag_width'>
                    <p>Timestamp</p>
                  </div>
                  {
                    txDetails?.timestamp ?
                      <div className='block_data gap-2'>
                        <img src='/assets/image/watch_icon.svg' alt="icon" />
                        <p>{formatTime(txDetails.timestamp)}</p>
                      </div>
                      : '-'
                  }
                </div>
                <div className='transaction_detail_div'>
                  <div className='block_data tag_width'>
                    <p>Transactions</p>
                  </div>
                  <div className='block_data gap-2'>
                    <Link to={`/block/${blockDetails?.index || 0}`} className='font_gray'>
                      {blockDetails?.transactions?.length || 0} transactions in this block
                    </Link>
                  </div>
                </div>
                {
                  txDetails?.message && !nftDetails &&
                  <div className='transaction_detail_div border-0'>
                    <div className='block_data tag_width'>
                      <p>Message</p>
                    </div>
                    <div className='block_data'>
                      {nftDetails ? <p>{'-'}</p> : <p>{txDetails?.message || '-'}</p>}
                    </div>
                  </div>
                }
              </div>
            </div>
            {
              nftDetails &&
              <div className='nft_details'>
                <div className='block_id p-0'>
                  <p>NFT Details</p>
                </div>
                <div className='nft_card'>
                  <div className='nft_title'>
                    <h3>Preview Your NFT</h3>
                  </div>
                  <div className='nft_img pointer' data-bs-toggle="modal"
                    data-bs-target="#nftModal">
                    <img src={nftDetails?.url} alt='NFT' />
                  </div>
                  <div className='nft_data'>
                    <h5>NFT Name :</h5>
                    <p>{nftDetails?.name || '-'}</p>
                  </div>
                  {
                    nftDetails?.description &&
                    <>
                      <div className='hr_border'></div>
                      <div className='nft_data'>
                        <h5>Description :</h5>
                        <div className='d-flex align-items-start gap-2'>
                          <p>{nftDetails?.description || '-'}</p>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            }
            <div className='block_data align-items-start pt-4'>
              <img src='/assets/image/bulb_icon.svg' alt="icon" />
              <p className='pt-0'>A transaction is a cryptographically signed instruction that changes the blockchain state. Block explorers track the details of all transactions in the network. Learn more about transactions in our <Link className='text-decoration-underline' to={'/whitepaper'}>Whitepaper</Link>.</p>
            </div>
          </div>
        </section>
      </div>

      {/* NFT Modal */}

      <div
        className="modal nft_modal fade"
        id="nftModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <img src={nftDetails?.url} alt='NFT' />
            </div>
          </div>
        </div>
      </div>

      <Footer className={'white_bg'} />
    </>
  )
}
