import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function SearchBar({ className, setShow }) {

  const [searchInput, setSearchInput] = useState('')
  const [isSearchNotFound, setIsSearchNotFound] = useState(false)
  const navigate = useNavigate()

  const fetchAddressData = async () => {
    try {
      const res = await axios.get(`https://fone-network-node.onrender.com/address/${searchInput}`)
      if (res?.data) {
        setShow?.()
        navigate(`/address/${searchInput}`, { state: res.data })
      } else {
        setIsSearchNotFound(true)
      }
    } catch (err) {
      setIsSearchNotFound(true)
    }
  }

  const fetchTxData = async () => {
    try {
      const res = await axios.get(`https://fone-network-node.onrender.com/transaction/${searchInput}`)
      if (res?.data?.transaction && res?.data?.block) {
        setShow?.()
        navigate(`/tx/${searchInput}`, { state: res.data })
      } else {
        fetchBlockDetailsFromBlockHash()
      }
    } catch (err) {
      fetchBlockDetailsFromBlockHash()
    }
  };

  const fetchBlockDetailsFromBlockNumber = async () => {
    try {
      const res = await axios.post(`https://fone-network-node.onrender.com/chain/block`, { index: Number(searchInput) })
      if (res?.data) {
        setShow?.()
        navigate(`/block/${searchInput}`, { state: res.data })
      }
      else
        setIsSearchNotFound(true)
    } catch (err) {
      setIsSearchNotFound(true)
    }
  }

  const fetchBlockDetailsFromBlockHash = async () => {
    try {
      const res = await axios.get(`https://fone-network-node.onrender.com/block/${searchInput}`)
      if (res.data?.block) {
        setShow?.()
        navigate(`/block/${searchInput}`, { state: res.data?.block })
      }
      else
        fetchAddressData()
    } catch (err) {
      fetchAddressData()
    }
  }

  const handleSearch = () => {
    if (!searchInput) return
    if (searchInput.length == 64) fetchTxData()
    else if (!isNaN(searchInput)) fetchBlockDetailsFromBlockNumber()
    else setIsSearchNotFound(true)
  }

  return (
    <>
      <div className={`explorer_select ${className || ''}`}>
        <input
          type="text"
          className="form-control form_search"
          placeholder="Search Address, Blocks or Transactions"
          autoFocus
          value={searchInput}
          onChange={e => {
            setSearchInput(e.target.value?.trim())
            setIsSearchNotFound(false)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSearch()
          }}
        />
        <button type='button' className='btn search_btn p-0' onClick={handleSearch}>
          <img src='/assets/image/search_icon.svg' alt='search' />
        </button>
        {isSearchNotFound && <p className='explorer_pera search_msg'>No search result found</p>}
      </div>
    </>
  )
}
